import React, {useState, useEffect} from 'react';
import Lottie from 'react-lottie';
import ReactAudioPlayer from 'react-audio-player';
import Button from './Button'
import Parallax from 'parallax-js'

import openerLottie from '../lotties/opener.json';
import gif1 from '../lotties/1.gif';
import gif2 from '../lotties/2.gif';
import gif3 from '../lotties/3.gif';
import gif4 from '../lotties/4.gif';

import asteroidLottie from '../lotties/asteroid.json';
import asteroidALottie from '../lotties/asteroida.json';
import asteroidBLottie from '../lotties/asteroidb.json';

import catLottie from '../lotties/cats.json';
import catALottie from '../lotties/catsa.json';
import catBLottie from '../lotties/catsb.json';

import spiderLottie from '../lotties/spider.json';
import spiderALottie from '../lotties/spidera.json';
import spiderBLottie from '../lotties/spiderb.json';

import robberLottie from '../lotties/robber.json';
import robberALottie from '../lotties/robbera.json';
import robberBLottie from '../lotties/robberb.json';

import villainLottie from '../lotties/villain.json';
import villainALottie from '../lotties/villaina.json';
import villainBLottie from '../lotties/villainb.json';

import endingLottie from '../lotties/ending.json';

import openerGif from '../assets/images/opener/opener.gif';
import endingGif from '../assets/images/ending/ending.gif'
import asteroidA1 from '../assets/images/asteroidA/1.gif'
import asteroidA2 from '../assets/images/asteroidA/2.gif'
import asteroidA3 from '../assets/images/asteroidA/3.gif'
import asteroidA4 from '../assets/images/asteroidA/4.gif'

import asteroidB1 from '../assets/images/asteroidB/1.gif'
import asteroidB2 from '../assets/images/asteroidB/2.gif'
import asteroidB3 from '../assets/images/asteroidB/3.gif'
import asteroidB4 from '../assets/images/asteroidB/4.gif'

import catsA1 from '../assets/images/catsA/1.gif'
import catsA2 from '../assets/images/catsA/2.gif'
import catsA3 from '../assets/images/catsA/3.gif'
import catsA4 from '../assets/images/catsA/4.gif'

import catsB1 from '../assets/images/catsB/1.gif'
import catsB2 from '../assets/images/catsB/2.gif'
import catsB3 from '../assets/images/catsB/3.gif'
import catsB4 from '../assets/images/catsB/4.gif'

import robberA1 from '../assets/images/robberA/1.gif'
import robberA2 from '../assets/images/robberA/2.gif'
import robberA3 from '../assets/images/robberA/3.gif'
import robberA4 from '../assets/images/robberA/4.gif'

import robberB1 from '../assets/images/robberB/1.gif'
import robberB2 from '../assets/images/robberB/2.gif'
import robberB3 from '../assets/images/robberB/3.gif'
import robberB4 from '../assets/images/robberB/4.gif'

import spiderA1 from '../assets/images/spiderA/1.gif'
import spiderA2 from '../assets/images/spiderA/2.gif'
import spiderA3 from '../assets/images/spiderA/3.gif'
import spiderA4 from '../assets/images/spiderA/4.gif'

import spiderB1 from '../assets/images/spiderB/1.gif'
import spiderB2 from '../assets/images/spiderB/2.gif'
import spiderB3 from '../assets/images/spiderB/3.gif'
import spiderB4 from '../assets/images/spiderB/4.gif'

import villainA1 from '../assets/images/villainA/1.gif'
import villainA2 from '../assets/images/villainA/2.gif'
import villainA3 from '../assets/images/villainA/3.gif'
import villainA4 from '../assets/images/villainA/4.gif'

import villainB1 from '../assets/images/villainB/1.gif'
import villainB2 from '../assets/images/villainB/2.gif'
import villainB3 from '../assets/images/villainB/3.gif'
import villainB4 from '../assets/images/villainB/4.gif'

const Selection = ({options, setScene, answer, setAnswer, scene}) => {

    const [play, setPlay] = useState(false);
    const [showOptions, setShowOptions] = useState(false)
    const [showNext, setShowNext] = useState(false)

    const animations = [
      // opener scene
      {
        audio: [
          '/audio/Opener/opener.mp3',
          '',
          ''
        ],
        lotties: [
          [openerGif],
          null,
          null
        ],
        optionTime: 0
      },
      // first scene (asteroid)
      {
        audio: [
          '/audio/Asteroid/asteroid.mp3',
          '/audio/Asteroid/asteroida.mp3',
          '/audio/Asteroid/asteroidb.mp3'
        ],
        lotties: [
          asteroidLottie,
          [asteroidA4, asteroidA3, asteroidA2, asteroidA1],
          [asteroidB4, asteroidB3, asteroidB2, asteroidB1]
        ],
        optionTime: 2.5
      },
      {
        audio: [
          '/audio/Cats/cats.mp3',
          '/audio/Cats/catsa.mp3',
          '/audio/Cats/catsb.mp3',
          
        ],
        lotties: [
          catLottie,
          [catsA4, catsA3, catsA2, catsA1],
          [catsB4, catsB3, catsB2, catsB1]
        ],
        optionTime: 6.5
      },
      {
        audio: [
          '/audio/Spider/spider.mp3',
          '/audio/Spider/spiderb.mp3',
          '/audio/Spider/spidera.mp3'
          
        ],
        lotties: [
          spiderLottie,
          [spiderB4, spiderB3, spiderB2, spiderB1],
          [spiderA4, spiderA3, spiderA2, spiderA1]
          
        ],
        optionTime: 4
      },
      {
        audio: [
          '/audio/Robber/robber.mp3',
          '/audio/Robber/robbera.mp3',
          '/audio/Robber/robberb.mp3',
          
        ],
        lotties: [
          robberLottie,
          [robberA4, robberA3, robberA2, robberA1],
          [robberB4, robberB3, robberB2, robberB1]
        ],
        optionTime: 5.2
      },
      {
        audio: [
          '/audio/Villian/villain.mp3',
          '/audio/Villian/villaina.mp3',
          '/audio/Villian/villainb.mp3',
          
        ],
        lotties: [
          villainLottie,
          [villainA4, villainA3, villainA2, villainA1],
          [villainB4, villainB3, villainB2, villainB1]
          
        ],
        optionTime: 3.5
      },
      {
        audio: [
          '/audio/Ending/ending.mp3',
          '',
          ''         
        ],
        lotties: [
          [endingGif],
          null,
          null
        ],
        optionTime: 0
      }
    ]

    const nextScene = () => {
      if (scene == 6) {
        setScene(1)
      } else {
        setScene(scene+2)
      }
      setAnswer(null)
      setShowNext(false)
      
    }

    const defaultOptions = {
        loop: scene !== 0,
        autoplay: play, 
        animationData: answer ? [] : animations[scene].lotties[0],
        rendererSettings: {
          scaleMode: 'contain',
          // preserveAspectRatio: 'xMidYMid slice'
        }
      };

    const submitAnswer = (i) => {
      setAnswer(i);
      setShowOptions(false)
    }
    
    useEffect(() => {
      let arr = [
        '1',
        '0.7',
        '0.4',
        '0.2'
      ]
      let images = document.querySelectorAll('.lottie img');
      images = Array.from(images)
      images.forEach((img, i) => {
        img.setAttribute('data-depth', arr[i])
      })
      const scene = document.getElementById('lottie', {
        relativeInput: true
      });
      const parallaxInstance = new Parallax(scene);
    })

    return (
        <div className="selection-container">
          <div id="lottie" className={showOptions ? 'lottie fade-out' : 'lottie'}>         
            <Lottie options={defaultOptions}
              height={'70vh'}
              width={'98vw'}
              isStopped={false}
              isPaused={false}
              eventListeners={[
                {
                  eventName: 'loaded_images',
                  callback: () => {
                    setPlay(true)
                  },
                }
              ]}
            />
            {
              answer ? animations[scene].lotties[answer] ? 
                animations[scene].lotties[answer].map(img => <img src={img}/>) : <></> : <></>
            }
            {
              scene == 0 || scene == 6 ? animations[scene].lotties[0].map(img => <img src={img}/>) : <></>
            }
          </div>
          <div className={"button-container " + (showOptions ? 'active' : '')} >
            {
            options.map((o, i) => {
              return <Button clickHandler={() => submitAnswer(i+1)} text={o.title} />  
            })
            }
          </div>
          <div className={"next-container " + (showNext ? 'active' : '')} >
            <Button clickHandler={() => nextScene()} text={scene !== 6 ? 'next...' : 'play again'} />
          </div>
          <ReactAudioPlayer
            src={answer ? animations[scene].audio[answer] : animations[scene].audio[0]}
            autoPlay={true}
            listenInterval={1000}
            onListen={e => {
              if (animations[scene].optionTime && !answer) {
                if (e > animations[scene].optionTime) {
                  setShowOptions(true)
                }
              }
            }}
            onEnded={(e) => {
              if (scene == 0) {
                setScene(scene+2)
                setAnswer(null)
              } else if (answer || scene == 6) {
                setShowNext(true);
              }
            }}
          />
          {
            scene > 0 ?
            <ReactAudioPlayer
            src={scene < 6 ? '/audio/looping.mp3' : '/audio/ending.mp3'}
            autoPlay={true}
            loop={scene < 6 ? true : false}
          /> : <></>
          }       
        </div>
    )
}

export default Selection;