import React, { useState, useEffect } from 'react';
import Title from './Title.js';
import Selection from './Selection';
import ReactAudioPlayer from 'react-audio-player';
// import Audio from '../../public/audio/Asteroid/asteroid.mp3'

const Scene = ({scene, setScene, answer, setAnswer}) => {

    const [showOptions, setShowOptions] = useState(false)

    const optionsMap = [
        [],
        [
            {title:'Fly into outer space to try to destroy the asteroid with a super punch? '},
            {title:'Run and hide! What are you going to do against an asteroid? '}
        ],
        [
            {title:'Face your fear and climb the tree to get it out of the tree? '},
            {title:'Use a fresh fish to convince the cat to come down from the tree to you?'}
        ],
        [
            {title:'Attempt to fight the spiders using your super strength? It will probably be a great photo opportunity?'},
            {title:'Make a giant bottle of bug repellent and spray it at the spiders?'}
        ],
        [
            {title:'Try to chase down the robber using your superspeed? '},
            {title:'Call for back up from the police?'}
        ],
        [
            {title:'Engage your enemy in hand-to-hand combat in an epic battle? '},
            {title:'Try to negotiate and appeal to his feelings? '}
        ],
        []
    ]

    return (
        <>
            {/* display number of scene for dev */}
            <p style={{
                display: window.location.origin.indexOf('localhost') !== -1 ? 'block' : 'none',
                position:'absolute',
                top:0,
                left:0,
                zIndex:9999
            }}>Scene {scene}: {answer}</p>
            {/* end scene for dev */}

            {/* <Title title={titleMap[scene]}/> */}

            {
                scene > 0 ? 
                <Selection 
                    options={optionsMap[scene-1]} 
                    setScene={setScene} 
                    scene={scene-1} 
                    answer={answer} 
                    setAnswer={setAnswer}
                    showOptions={showOptions}/> : <></>
            }
        </>
    )
}

export default Scene;