import React, {useState, useEffect} from 'react';
import Parallax from 'parallax-js'
import Scene from './components/Scene'
import './styles.min.css'
import Button from './components/Button'

import openerGif from './assets/images/opener/opener.gif'

import endingGif from './assets/images/ending/ending.gif'
import asteroidA1 from './assets/images/asteroidA/1.gif'
import asteroidA2 from './assets/images/asteroidA/2.gif'
import asteroidA3 from './assets/images/asteroidA/3.gif'
import asteroidA4 from './assets/images/asteroidA/4.gif'

import asteroidB1 from './assets/images/asteroidB/1.gif'
import asteroidB2 from './assets/images/asteroidB/2.gif'
import asteroidB3 from './assets/images/asteroidB/3.gif'
import asteroidB4 from './assets/images/asteroidB/4.gif'

import catsA1 from './assets/images/catsA/1.gif'
import catsA2 from './assets/images/catsA/2.gif'
import catsA3 from './assets/images/catsA/3.gif'
import catsA4 from './assets/images/catsA/4.gif'

import catsB1 from './assets/images/catsB/1.gif'
import catsB2 from './assets/images/catsB/2.gif'
import catsB3 from './assets/images/catsB/3.gif'
import catsB4 from './assets/images/catsB/4.gif'

import robberA1 from './assets/images/robberA/1.gif'
import robberA2 from './assets/images/robberA/2.gif'
import robberA3 from './assets/images/robberA/3.gif'
import robberA4 from './assets/images/robberA/4.gif'

import robberB1 from './assets/images/robberB/1.gif'
import robberB2 from './assets/images/robberB/2.gif'
import robberB3 from './assets/images/robberB/3.gif'
import robberB4 from './assets/images/robberB/4.gif'

import spiderA1 from './assets/images/spiderA/1.gif'
import spiderA2 from './assets/images/spiderA/2.gif'
import spiderA3 from './assets/images/spiderA/3.gif'
import spiderA4 from './assets/images/spiderA/4.gif'

import spiderB1 from './assets/images/spiderB/1.gif'
import spiderB2 from './assets/images/spiderB/2.gif'
import spiderB3 from './assets/images/spiderB/3.gif'
import spiderB4 from './assets/images/spiderB/4.gif'

import villainA1 from './assets/images/villainA/1.gif'
import villainA2 from './assets/images/villainA/2.gif'
import villainA3 from './assets/images/villainA/3.gif'
import villainA4 from './assets/images/villainA/4.gif'

import villainB1 from './assets/images/villainB/1.gif'
import villainB2 from './assets/images/villainB/2.gif'
import villainB3 from './assets/images/villainB/3.gif'
import villainB4 from './assets/images/villainB/4.gif'

import rotate from './assets/images/rotate-screen.gif'


function App() {

  const [loading, setLoading] = useState(true);
  const [scene, setScene] = useState(0);
  const [answer, setAnswer] = useState(null);
  const [isImage, setImage] = useState(false);
  const [numImages, setNumImages] = useState(0)

  const updateLoader = () => {
    const newNum = numImages + 1;
    setNumImages(newNum)
  }

  const enableGyroscope = () => {
    //  request gyroscope permission
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      // iOS 13+
      DeviceMotionEvent.requestPermission()
      .then(response => {
        if (response == 'granted') {
          window.addEventListener('devicemotion', (e) => {
            // do something with e
          })
        }
      })
      .catch(console.error)
    } else {
      // non iOS 13+
      if (typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission()
        .then(response => {
          if (response == 'granted') {
            window.addEventListener('deviceorientation', (e) => {
              // do something with e
            })
          }
        })
        .catch(console.error)
      }    
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', function() {
      enableGyroscope();
    })

    document.body.click();
  }, true)

  return (
    <div className={"main"}>
      <p style={{
          display: window.location.origin.indexOf('localhost') !== -1 ? 'block' : 'none',
          position:'absolute',
          top:60,
          left:0,
          zIndex:9999
      }}>Images Loaded: {numImages}</p>
      <div id="scene" className={"main__container scene-"+scene}>

        <Scene scene={scene} setScene={setScene} answer={answer} setAnswer={setAnswer}/>

        {scene == 0 ? 
          <div className="loader pl-scene">
            {
             numImages != 9 ?
              <div className="loader__loading">
                <div className="loader__loading__inner" style={{
                  width:numImages/9*100+'%'
                }}>Loading...</div>
              </div> : <></>
            }
            {
              numImages == 9 ? <Button text="start" clickHandler={() => setScene(1)}/> : <></>
            }
          </div> : <></>
        }
        
      </div>
      <div className="device-orientation">
        <img src={rotate}/>
      </div>
      <div style={{display: 'none'}}>
        {
          scene == 0 ?
          <>
            <img src={openerGif} onLoad={updateLoader} />
            <img src={asteroidA1} onLoad={updateLoader}  />
            <img src={asteroidA2} onLoad={updateLoader}  />
            <img src={asteroidA3} onLoad={updateLoader}  />
            <img src={asteroidA4} onLoad={updateLoader}  />
            <img src={asteroidB1} onLoad={updateLoader}  />
            <img src={asteroidB2} onLoad={updateLoader}  />
            <img src={asteroidB3} onLoad={updateLoader}  />
            <img src={asteroidB4} onLoad={updateLoader}  />
          </> : <></>
        }
        {
          scene > 0 ? 
          <>
          <img src={catsA1} onLoad={updateLoader}  />
            <img src={catsA2} onLoad={updateLoader}  />
            <img src={catsA3} onLoad={updateLoader}  />
            <img src={catsA4} onLoad={updateLoader}  />
            <img src={catsB1} onLoad={updateLoader}  />
            <img src={catsB2} onLoad={updateLoader}  />
            <img src={catsB3} onLoad={updateLoader}  />
            <img src={catsB4} onLoad={updateLoader}  />
            <img src={spiderA1} onLoad={updateLoader}  />
            <img src={spiderA2} onLoad={updateLoader}  />
            <img src={spiderA3} onLoad={updateLoader}  />
            <img src={spiderA4} onLoad={updateLoader}  />
            <img src={spiderB1} onLoad={updateLoader}  />
            <img src={spiderB2} onLoad={updateLoader}  />
            <img src={spiderB3} onLoad={updateLoader}  />
            <img src={spiderB4} onLoad={updateLoader}  />
            <img src={robberA1} onLoad={updateLoader}  />
            <img src={robberA2} onLoad={updateLoader}  />
            <img src={robberA3} onLoad={updateLoader}  />
            <img src={robberA4} onLoad={updateLoader}  />
            <img src={robberB1} onLoad={updateLoader}  />
            <img src={robberB2} onLoad={updateLoader}  />
            <img src={robberB3} onLoad={updateLoader}  />
            <img src={robberB4} onLoad={updateLoader}  />
            <img src={villainA1} onLoad={updateLoader}  />
            <img src={villainA2} onLoad={updateLoader}  />
            <img src={villainA3} onLoad={updateLoader}  />
            <img src={villainA4} onLoad={updateLoader}  />
            <img src={villainB1} onLoad={updateLoader}  />
            <img src={villainB2} onLoad={updateLoader}  />
            <img src={villainB3} onLoad={updateLoader}  />
            <img src={villainB4} onLoad={updateLoader}  />
            <img src={endingGif} onLoad={updateLoader}  />
          </> : <></>
        }
      </div>
    </div>
  );
}

export default App;
